import React, { Fragment } from "react"
import { Hero, Intro } from "../components/StandartPage/index"

import Seo from "../components/seo"

const SikcaSorulanSorularPage = () => (
  <Fragment>
    <Seo
      title="Sıkça Sorulan Sorular"
      description="Bilişim Hukuku Alanında Sıkça Sorulan Sorular - Feneomen Avukatı - Influencer Avukatı"
    />
    <Hero
      title="Sıkça Sorulan Sorular"
      subtitle="Bilişim Hukuku Alanında Sıkça Sorulan Sorular"
    />
    <Intro
      title="Bilişim Hukuku Avukatı Ne Yapar?"
      description="Birçok uygulamaya sahip bilgisayarlar, bizi çevreleyen hemen hemen tüm cihazlara ve nesnelere yüklenir. Bu teknolojiler, eksiksiz, çok uluslu ağ oluşturmanın sonucu olarak bugün birbirine kenetleniyor. Bu tür teknik başarılar, sayısız yasal soruna ve çatışma alanlarına yol açmaktadır.
      <br/><br/>
      Avukat Bilişim, 10 yılı aşkın bir süredir bilgi teknolojisinin (BT) yasal yönleriyle ilgilenmektedir. Hukuk bürosuyla konuştuk ve bilişim hukuku, kendisi ve Avukat Bilişim Hukuk Bürosu” (Avukatlık) hukuk firması hakkında bazı ilginç gerçekleri öğrendik.
      <br/><br/>
      Teknoloji, hukuk alanını çeşitli şekillerde etkilemiştir. Firmalar, uygulamalarını yürütmelerine yardımcı olacak teknoloji çözümleri uyguluyor.
      <br/><br/>
      <h2>Influencer Nedir?
      </h2>
      Influencer nedir? Eh, terimin çok fazla atıldığını biliyoruz. Söylemek istediğim, herkesin bir Influencer olduğu, değil mi? Çevrimiçi bir sosyal medya hesabı olan herkes, uzmanlığı olan herkes, başkalarının dinlediği bir sesi olan herkes etki yaratabilir. Açıkçası, ünlüler otomatik olarak iyi ya da kötü en azından bazı nüfus üzerinde etkiye sahipler. Ve ünlü hale gelen Influencerler, işleri ve iyi niyeti yönlendirmek için ürünleri ve markaları destekleme yeteneğine sahiptir. Ancak, daha az ünlü olan, ancak yine de etki gösterebilen insanlar olan bir mikro-Influencer pazarı var.
      "
    />
  </Fragment>
)

export default SikcaSorulanSorularPage
